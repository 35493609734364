import logo from './logo.svg';
import './App.css';
import {Route, Routes, Link} from 'react-router-dom';
import Home from './pages/Home';
import Layout from './pages/Layout';
import NoPage from './pages/NoPage'
import Words from './pages/Words';
import Contact from './pages/Contact';
import Lda from './pages/Lda';
import WordCloud from './pages/WordCloud';

function App() {
  return (
    <div className = 'navigation'>
    <nav>
        <ul>
          <li>
            <Link to="/">Search</Link>
          </li>
          <li>
            <Link to="/wordcloud">By Time</Link>
          </li>
          <li>
            <Link to="/words" onClick={() => {window.location.href="/words/"}}>By Word</Link>
          </li>
          <li>
            <Link to="/lda">LDA</Link>
          </li>
          {/* <li>
            <Link to="/contact">Contact</Link>
          </li> */}
        </ul>
      </nav>
    <div className="App-header">
    <Routes>
      <Route index element={<Home />} />
      <Route path= "/home" element={<Home />} />
      <Route path= "/wordcloud" element={<WordCloud />} />
      <Route path= "/words/:temp" element={<Words/>} />
      <Route path= "/words" element={<Words/>} />
      <Route path= "/lda" element={<Lda />} />
      <Route path= "/contact" element={<Contact />} />
      <Route path="*" element={<NoPage />} />
      <Route/>
    </Routes>
    </div>
    </div>

  );
}

export default App;
