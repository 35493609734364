import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom'
import Plot from "react-plotly.js";
import { Outlet, Link } from "react-router-dom";


const Words = () => {
  const [value, setValu] = useState();
  const [x, setX] = useState();
  const [y, setY] = useState();
  const params = useParams()
  const [allwords, setAllwords] = useState([]);
  const [option_one, setOptionOne] = useState();
  const [option_two, setOptionTwo] = useState();
  const [option_three, setOptionThree] = useState();
  const [option_four, setOptionFour] = useState();
  const [option_five, setOptionFive] = useState();
  const [semaphore, SetSemaphore] = useState('None')


  useEffect(() => {
    if (x == null || x == []) {
      console.log("USE EFFECT")
      console.log("temp 1: ", params.temp)
      setValu(params.temp)
      PopulateGraph(params.temp)
      console.log("allwords in populate: ", allwords)
      console.log("out value: ", value)
      console.log("temp: ", params.temp)
      console.log("x: ", x)
      console.log("y: ", y)
    }
    else {
      console.log(x)
    }
  }, [allwords])

  if (semaphore == 'None') {
    // setOptionOne(allwords[Math.floor(Math.random() * allwords.length)])
    // setOptionTwo(allwords[Math.floor(Math.random() * allwords.length)])
    // setOptionThree(allwords[Math.floor(Math.random() * allwords.length)])
    // setOptionFour(allwords[Math.floor(Math.random() * allwords.length)])
    // setOptionFive(allwords[Math.floor(Math.random() * allwords.length)])
    SetSemaphore('sem')
  }

  // const option_one = allwords[Math.floor(Math.random() * allwords.length)];
  // const option_two = allwords[Math.floor(Math.random() * allwords.length)];
  // const option_three = allwords[Math.floor(Math.random() * allwords.length)];
  // const option_four = allwords[Math.floor(Math.random() * allwords.length)];
  // const option_five = allwords[Math.floor(Math.random() * allwords.length)];
  //        <a href="/words/{option_one}">{option_one}</a>



  if (params.temp == null) {
    return (
      <div className="wordlinks">
        <nav>
          <ul>
            Suggestions:
            <li>
              <Link to={"/words/" + option_one} onClick={() => { window.location.href = "/words/" + option_one }}>{option_one}</Link>
            </li>
            <li>
              <Link to={"/words/" + option_two} onClick={() => { window.location.href = "/words/" + option_two }}>{option_two}</Link>
            </li>
            <li>
              <Link to={"/words/" + option_three} onClick={() => { window.location.href = "/words/" + option_three }}>{option_three}</Link>
            </li>
            <li>
              <Link to={"/words/" + option_four} onClick={() => { window.location.href = "/words/" + option_four }}>{option_four}</Link>
            </li>
            <li>
              <Link to={"/words/" + option_five} onClick={() => { window.location.href = "/words/" + option_five }}>{option_five}</Link>
            </li>
          </ul>
          {/* <a href={"/words/" + option_one}>{option_one}</a>
        <a href={"/words/" + option_two}>{option_two}</a>
        <a href={"/words/" + option_three}>{option_three}</a>
        <a href={"/words/" + option_four}>{option_four}</a>
        <a href={"/words/" + option_five}>{option_five}</a> */}
        </nav>
      </div>
    );
  }
  // <h1>Poop {params.temp}</h1>
  return (
    <div className="App-header">
      <Plot
        data={[
          {
            x: x,
            y: y,
            fill: "tonexty",
            type: "scatter",
            marker: { color: "#8000DE" }
          }
        ]}
        config={{ "displayModeBar": 0, "showTips": 0 }}
        layout={{
          width: 500,
          height: 300,
          title: value,
          yaxis: {
            range: [1, 500],
            type: "linear"
          },
          xaxis: {
            range: [1890, 1940],
            type: "linear"
          },
          margin: { t: 40, b: 20, l: 30, r: 30 },
          paper_bgcolor: "rgb(243, 243, 243)",
          plot_bgcolor: "#f3f3f3",
          family: "Courier New, monospace"
        }}

      />
      <div className="wordlinks">

        <nav>
          <ul>
            Try:
            <li>
              <Link to={"/words/" + option_one} onClick={() => { window.location.href = "/words/" + option_one }}>{option_one}</Link>
            </li>
            <li>
              <Link to={"/words/" + option_two} onClick={() => { window.location.href = "/words/" + option_two }}>{option_two}</Link>
            </li>
            <li>
              <Link to={"/words/" + option_three} onClick={() => { window.location.href = "/words/" + option_three }}>{option_three}</Link>
            </li>
            <li>
              <Link to={"/words/" + option_four} onClick={() => { window.location.href = "/words/" + option_four }}>{option_four}</Link>
            </li>
            <li>
              <Link to={"/words/" + option_five} onClick={() => { window.location.href = "/words/" + option_five }}>{option_five}</Link>
            </li>
          </ul>
          {/* <a href={"/words/" + option_one}>{option_one}</a>
        <a href={"/words/" + option_two}>{option_two}</a>
        <a href={"/words/" + option_three}>{option_three}</a>
        <a href={"/words/" + option_four}>{option_four}</a>
        <a href={"/words/" + option_five}>{option_five}</a> */}
        </nav>
      </div>
    </div>
  );
  async function PopulateGraph(value) {
    console.log("value in populate: ", value)
    const API = 'https://b7wlgenx2m.execute-api.us-east-1.amazonaws.com/staging/words/' + value;
    const fetchPost = () => {
      fetch(API)
        .then((res) => res.json())
        .then((res) => {
          const arr = res
          if (arr[0] != []) {
            setY(arr[0])
            setX(arr[1])
          }
          setAllwords(arr[2])
          console.log(arr[2].length)
          setOptionOne(arr[2][Math.floor(Math.random() * arr[2].length)])
          setOptionTwo(arr[2][Math.floor(Math.random() * arr[2].length)])
          setOptionThree(arr[2][Math.floor(Math.random() * arr[2].length)])
          setOptionFour(arr[2][Math.floor(Math.random() * arr[2].length)])
          setOptionFive(arr[2][Math.floor(Math.random() * arr[2].length)])

        })

    }

    await fetchPost()

  }
};

export default Words;