import RangeSlider from 'react-range-slider-input';
import React, { useEffect, useState } from "react";
import { TagCloud } from 'react-tagcloud'
import './../App.css';


const WordCloud = () => {
  const [value, setVal] = useState([1890, 1940]);
  const [data, setData] = useState([
    { value: 'Loading...', count: 38 },
    // { value: 'word', count: 30 },
    // { value: 'cloud', count: 29 },
    // { value: 'digital', count: 35 },
    // { value: 'dreams', count: 28 },
  ])
  useEffect(()=>{
    GetDatafromLambda()
  }, [])
  const [post, getPost] = useState([])
    return (
      <div className="App-header">
      <div className="cloud">
      <TagCloud
        minSize={16}
        maxSize={47}
        tags={data}
        //onClick={tag => alert(`'${tag.value}' was selected!`)}
        onClick={tag => window.location.href='/words/'+ tag.value}
      />
      </div>
      <div className="Display-text">
        <center>
          <p>
            Freud's most frequently used words between {value[0]} - {value[1]}
          </p>
        </center>
      </div>
      <div id="slide_cont">
        <RangeSlider
          min={1890}
          max={1940}
          defaultValue={[1890, 1940]}
          value={value}
          onInput={setVal}

        />
      </div>
      <button onClick={GetDatafromLambda}>Generate word-cloud</button>
      </div>

    );
    async function GetDatafromLambda(){
      // const API = 'https://amggjkj3x3.execute-api.us-east-1.amazonaws.com/staging/words/' + value[0] + '-' + value[1];
      const API = 'https://1v58lex373.execute-api.us-east-1.amazonaws.com/staging/wcdata/' + value[0] + '-' + value[1];
      const fetchPost = () => {
        fetch(API)
          .then((res) => res.json())
          //.then((res) => setData(res))
          .then((res) => {
            //console.log(JSON.parse('{"name":"John", "age":30, "city":"New York"}'))
            //console.log(JSON.parse(res))
            const arr = JSON.parse(res)
            setData(arr)
          })
          
      }
  
      fetchPost()
  
    }
  };
  
  export default WordCloud;