import './../App.css';

const Contact = () => {
    return (
      <div className="contact">
        <h2>What is this?</h2>
        <p>
        This is freud.ninja! It's where I try to use natural language processing (NLP) methods to analyze the Freudian corpus! <br />
        With time I will try to offer more algorithmic approaches  to the Freudian texts, providing different perspectives on the writings through the lense of machine learning.
        </p>
        <h2>I don't agree with something!</h2>
        <p>That's great! Everything here is algorithmically extracted, so if there's a mistake, I really want to know so I can fix it! This is an early version and should be regarded as a POC.<br />
        </p>
      </div>
    );
  };
  
  export default Contact;