import RangeSlider from 'react-range-slider-input';
import React, { useEffect, useState, useRef } from "react";
import { TagCloud } from 'react-tagcloud'
import './../App.css';


const Home = () => {
  const [value, setVal] = useState([1890, 1940]);
  const [data, setData] = useState([])
  const [post, getPost] = useState([])
  const [name, setName] = useState("");
  const [searchKey, setsearchKey] = useState("");
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <div className="App-header">
      <center>
        <form>
          <input
            type="text"
            ref={inputRef}
            size="60"
            value={searchKey}
            onChange={(e) => setsearchKey(e.target.value)}
            onKeyUp={(e) => setsearchKey(e.target.value)
            }
          />
          <button ref={buttonRef} type="submit" onClick={(e) => GetDatafromLambda(e)}>Search</button>
        </form>
      </center>
      <div className="Display-text">
        {data.map((value, index) => {
          return (
            <div key={index}>
              <p><b>{value.title}</b> (p.{value.page}) "{value.value}"</p>
            </div>
          );
        })}
        <br></br>
        <center>
          <a href='/contact'>what is this?</a>
        </center>
      </div>
    </div>

  );
  async function GetDatafromLambda(e) {
    e.preventDefault();
    console.log(searchKey)
    const API = 'https://n4x7gmr7ok.execute-api.us-east-1.amazonaws.com/staging/search/' + encodeURIComponent(searchKey);
    const fetchPost = () => {
      fetch(API)
        .then(console.log('poop'))
        .then((res) => res.json())
        //.then((res) => setData(res))
        .then((res) => {
          //console.log(JSON.parse('{"name":"John", "age":30, "city":"New York"}'))
          console.log(res)
          //console.log(JSON.parse(res))
          //const arr = JSON.parse(res)
          setData(res)
        })

    }

    if (searchKey.trim() === ''){
      setData([])
    }
    else {
      fetchPost()
    }

  }
};

export default Home;