import RangeSlider from 'react-range-slider-input';
import React, { useEffect, useState } from "react";
import { TagCloud } from 'react-tagcloud'
import './../App.css';


const Lda = () => {
  const [value, setVal] = useState([1890, 1940]);
  const [data, setData] = useState([
    { value: 'Loading...', count: 38 },
    // { value: 'word', count: 30 },
    // { value: 'cloud', count: 29 },
    // { value: 'digital', count: 35 },
    // { value: 'dreams', count: 28 },
  ])
  useEffect(() => {
    GetDatafromLambda()
  }, [])
  const [post, getPost] = useState([])
  return (
    <div className="App-header">
      <div className="cloud">
        <center>
          {data.map(
            ({ string }) => <li>({string})</li>
          )}
        </center>
      </div>
      <div className="Display-text">
        <center>
          <p>
            Freud's top LDA topics between {value[0]} - {value[1]}
          </p>
        </center>
      </div>
      <div id="slide_cont">
        <RangeSlider
          min={1890}
          max={1940}
          defaultValue={[1890, 1940]}
          value={value}
          onInput={setVal}

        />
      </div>
      <button onClick={GetDatafromLambda}>Get data</button>
    </div>

  );
  async function GetDatafromLambda() {
    // const API = 'https://amggjkj3x3.execute-api.us-east-1.amazonaws.com/staging/words/' + value[0] + '-' + value[1];
    const API = 'https://jk7wuxqht9.execute-api.us-east-1.amazonaws.com/staging/ldaapi/' + value[0] + '-' + value[1];
    const fetchPost = () => {
      fetch(API)
        .then((res) => res.json())
        //.then((res) => setData(res))
        .then((res) => {
          //console.log(JSON.parse('{"name":"John", "age":30, "city":"New York"}'))
          console.log(res)
          const arr = res
          setData(arr)
        })

    }

    fetchPost()

  }
};

export default Lda;